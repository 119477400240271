import { Link, NavLink } from "react-router-dom";
import React, { useState } from "react";
import ftLogo from "../../../assets/images/logo2.png";
import { API_ENDPOINT_URL, APP_PREFIX } from "../../../utils/appConstants";
import { postRequest } from "../../../utils/requests";
import { toast } from "react-toastify";

const Footer = () => {
  const [errors, setErrors] = useState(false);
  const [newsletter, setNewsletter] = useState({
    name: "",
    email: "",
  });

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!newsletter.name) {
      isValid = false;
      errors["name"] = "This filed is required.";
    }
    if (!newsletter.email) {
      isValid = false;
      errors["email"] = "This filed is required.";
    }
    if (newsletter.email) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(newsletter.email)) {
        isValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }
    setErrors(errors);
    return isValid;
  };

  const subscribe = (event) => {
    event.preventDefault();
    if (validate()) {
      postRequest(
        `${API_ENDPOINT_URL}/f-end/mail-chimp/subscribe`,
        newsletter
      ).then((res) => {
        if (res) {
          toast.success("You are successfully subscribed to our newsletters");
          setNewsletter({ name: "", email: "" });
        }
      });
    }
  };

  return (
    <>
      <div className="ftAreaWrap bg-primary text-center">
        <aside className="ftAside">
          <div className="container">
            <h3 className="text-white">Sign up to our Newsletter</h3>
            <form className="ftaSignupForm" onSubmit={subscribe}>
              <div className="form-row mx-n3 justify-content-center">
                <div className="px-3 chCol5">
                  <div className="form-group position-relative">
                    <input
                      type="text"
                      className="form-control fcAltervii w-100"
                      value={newsletter.name}
                      onChange={(e) =>
                        setNewsletter({ ...newsletter, name: e.target.value })
                      }
                      placeholder="Name"
                    />
                    {errors.name && (
                      <small className="support-error">{errors.name}</small>
                    )}
                  </div>
                </div>
                <div className="px-3 chCol5">
                  <div className="form-group position-relative">
                    <input
                      type="email"
                      className="form-control fcAltervii w-100"
                      value={newsletter.email}
                      onChange={(e) =>
                        setNewsletter({ ...newsletter, email: e.target.value })
                      }
                      placeholder="Email"
                    />
                    {errors.email && (
                      <small className="support-error">{errors.email}</small>
                    )}
                  </div>
                </div>
                <div className="px-3 chCol2">
                  <button type="submit" className="btn btn-warning w-100">
                    Subscribe
                  </button>
                </div>
              </div>
            </form>
          </div>
        </aside>
        <footer id="pageFooter">
          <div className="container">
            <div className="ftLogoWrap">
              <Link to="/">
                <img
                  className="logoimageii img-fluid"
                  src={ftLogo}
                  width="255"
                  height="65"
                  alt="petbond"
                />
              </Link>
            </div>
            <div className="row">
              <div className="col-12 col-lg-10 offset-lg-1">
                <ul className="list-unstyled ftList d-none d-md-flex justify-content-center flex-wrap">
                  <li>
                    <Link to={`/pet-listing`} className="aa-ftLink">
                      Find a Pet
                    </Link>
                  </li>
                  <li>
                    <Link to={`/for-breeder`} className="aa-ftLink">
                      Sell My Dog
                    </Link>
                  </li>
                  <li>
                    <a
                      href="https://mypetbond.com/petscorner/"
                      className="aa-ftLink"
                    >
                      Pets Corner
                    </a>
                  </li>
                  <li>
                    <Link to={`/petbond-match`} className="aa-ftLink">
                      My Vetbond Match
                    </Link>
                  </li>
                  <li>
                    <Link to={`${APP_PREFIX}/login`} className="aa-ftLink">
                      Login
                    </Link>
                  </li>
                  <li>
                    <Link to={`/contact-us`} className="aa-ftLink">
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <a
                      href="https://mypetbond.com/petscorner/general-news/paw4paw/"
                      className="aa-ftLink"
                    >
                      PAW4PAW
                    </a>
                  </li>
                  <li>
                    <Link to={`/faq`} className="aa-ftLink">
                      FAQs
                    </Link>
                  </li>
                  <li>
                    <Link to={`/terms-conditions`} className="aa-ftLink">
                      Terms &amp; Conditions
                    </Link>
                  </li>
                  <li>
                    <Link to={`/cookie-policy`} className="aa-ftLink">
                      Cookie Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={`/privacy-policy`} className="aa-ftLink">
                      Privacy Policy
                    </Link>
                  </li>
                  {/* <li>
                    <Link to={`/about-us`} className="aa-ftLink">
                      Mission Statement
                    </Link>
                  </li> */}
                </ul>
                <ul className="list-unstyled ftSocialList d-flex justify-content-center align-items-center mb-0">
                  <li>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/petbondireland"
                      className="fab fa-facebook"
                    ></a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/pet_bond/"
                      className="fab fa-instagram"
                    ></a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://twitter.com/petbond_ireland"
                      className="fab fa-twitter"
                    ></a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.youtube.com/channel/UCjL7H8i0eZmDCDs21CbiWkQ"
                      className="fab fa-youtube"
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;

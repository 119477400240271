import allianz from '../../assets/images/allianz.jpg';
import dogstrust from '../../assets/images/dogs-trust.jpg';
import veterinaryireland from '../../assets/images/veterinary-ireland.jpg';
import ivna from '../../assets/images/IVNA_logo.jpg';
import flyoutPuppyIsolated from '../../assets/images/flyout-puppy-isolated.png';
import gainLogo from '../../assets/images/gain-logo.png';
import Slider from 'react-slick';
import {API_ENDPOINT_URL, STORIES_ENDPOINT_URL, STORIES_MEDIA_URL} from '../../utils/appConstants';
import {Link} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {getRequest} from '../../utils/requests';
import {getStars} from '../../utils';
import axios from 'axios';
import {Helmet} from 'react-helmet-async';

function SampleNextArrow(props) {
  const {className, style, onClick} = props;
  return (
    <div
      className={`${className} slickNext ssArrowVii d-none d-md-block aricomoon-ararrowRight position-absolute slick-arrow`}
      style={{...style, display: 'block', color: 'black'}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const {className, style, onClick} = props;
  return (
    <div
      className={`${className} slickPrev ssArrowVii d-none d-md-block aricomoon-ararrowLeft position-absolute slick-arrow`}
      style={{...style, display: 'block', color: 'black'}}
      onClick={onClick}
    />
  );
}

const Home = () => {

  const settings = {
    dots: true,
    arrows: true,
    appendDots: dots => (
      <div>
        <ul
          className="ardotsList ardotsListvi d-flex justify-content-center d-md-block list-unstyled mb-0"> {dots} </ul>
      </div>
    ),
    autoplay: true,
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow/>,
    speed: 500,
    fade: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
  };

  const [adverts, setAdverts] = useState([]);
  const [stories, setStories] = useState([]);

  useEffect(() => {
    getRequest(`${API_ENDPOINT_URL}/f-end/advert/random-adverts`).then((res) => {
      if (res) {
        setAdverts(res.data.data);
      }
    });
    axios.get(STORIES_ENDPOINT_URL).then(async (res) => {
      if (res) {
        const posts = [];
        const slicedStories = res.data.slice(0, 3);
        const shortContentElement = document.createElement('p');
        for (const story of slicedStories) {
          shortContentElement.innerHTML = story.excerpt.rendered.replace(/<[^>]*>?/gm, '');
          let tempObject = {
            id: story.id,
            link: story.link,
            title: story.title.rendered,
            shortContent: shortContentElement.innerHTML,
            image: ''
          };

          const storyMedia = await axios.get(`${STORIES_MEDIA_URL}/${story.featured_media}`).catch((e) => console.log(e));
          if (storyMedia.data) {
            tempObject.image = storyMedia.data.source_url;
          }
          posts.push(tempObject);
        }
        setStories(posts);
      }
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Dogs for Sale in UK | Buy Dogs & Puppies | PetBond</title>
        <meta name="description"
              content="PetBond is the safest and most ethical way to buy dogs in UK. We work with approved breeders to help you find a new best friend. Find your dog or puppy today."/>
      </Helmet>
      <article className="arintroBlock text-center position-relative overflow-hidden w-100">
        <div className="alignHolder">
          <div className="align">
            <div className="arContainerLarge clearfix">
              <div className="row">
                <div className="col-12 col-md-6 order-md-2 position-static">
                  <div className="descrWrap">
                    <h1>Meet your lifelong best friend with peace of mind.</h1>
                    <p>Are you looking for a new lifelong friend? By choosing us, you will receive a
                      safe, ethical & trusted approved breeder. You also be a part of preventing
                      animal cruelty.<br/> Lets help you start your new journey today!</p>
                    <div className="arabBtnsWrap d-flex flex-wrap justify-content-center findpbtn">
                      <Link to={`/pet-listing`} className="arbtnWhite shadow btn text-uppercase">
                        FIND A PET
                        <i className="aricomoon-search icn"><span
                          className="sr-only">icon</span></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 order-md-1 position-static">
                  <div className="arabImgHolder inaccessible">
                    <img src="/images/image-mainpet.png" className="img-fluid" alt="img description"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span className="arabPinnerWrap inaccessible position-absolute h-100">
					<span className="arabPetIcn arabPetIcni position-absolute">
						<i className="aricomoon-thoughtDots"><span className="sr-only">icon</span></i>
					</span>
					<span className="arabPetIcn arabPetIcnii position-absolute">
						<i className="aricomoon-starDots"><span className="sr-only">icon</span></i>
					</span>
					<span className="arabPetIcn arabPetIcniii position-absolute">
						<i className="aricomoon-hearts"><span className="sr-only">icon</span></i>
					</span>
					<span className="arabPetIcn arabPetIcnv position-absolute">
						<i className="aricomoon-laoud"><span className="sr-only">icon</span></i>
					</span>
					<span className="arabPetIcn arabPetIcniv position-absolute d-none d-md-block">
						<i className="aricomoon-lhsRhs"><span className="sr-only">icon</span></i>
					</span>
					<span className="arabPetIcn arabPetIcnvi position-absolute">
						<i className="aricomoon-laoud1"><span className="sr-only">icon</span></i>
					</span>
					<span className="arabPetIcn arabPetIcnvii position-absolute d-none d-md-block">
						<i className="aricomoon-laoud1"><span className="sr-only">icon</span></i>
					</span>
				</span>
      </article>
      <section className="petProcessBlock">
        <div className="container">
          <header className="arHeadingHead text-center">
            <h2 className="h2Medium">Our process</h2>
          </header>
          <div className="row justify-content-center">
            <div className="col-12 col-md-4 d-md-flex col">
              <article
                className="aroppColumn w-100 d-md-flex flex-md-column text-center position-relative">
                <div className="arppcWrap">
                  <div className="arimgHolder">
                    <img src="/images/ardevimg01.png" className="img-fluid" alt="img description"/>
                  </div>
                  <h3>Step 1</h3>
                  <p>Visit a recommended vet near you and personally discuss the right choice for you. Or, rehome an approved best friend</p>
                </div>
                <div className="arBtnWrap">
                  <Link to={`/find-a-vet`} className="btn d-block w-100 mx-auto shadow">
                    My VetBond Match
                  </Link>
                </div>
              </article>
            </div>
            <div className="col-12 col-md-4 d-md-flex col">
              <article
                className="aroppColumn w-100 d-md-flex flex-md-column text-center position-relative">
                <div className="arppcWrap">
                  <div className="arimgHolder">
                    <img src="/images/ardevimg02.png" className="img-fluid" alt="img description"/>
                  </div>
                  <h3>Step 2</h3>
                  <p>Search for your new best friend with our approved selection of safe, ethical & trusted breeders.</p>
                </div>
                <div className="arBtnWrap">
                  <Link to={`/pet-listing`} className="btn d-block w-100 mx-auto shadow">Find my
                    Pet</Link>
                </div>
              </article>
            </div>
            <div className="col-12 col-md-4 d-md-flex col">
              <article
                className="aroppColumn w-100 d-md-flex flex-md-column text-center position-relative">
                <div className="arppcWrap">
                  <div className="arimgHolder">
                    <img src="/images/ardevimg03.png" className="img-fluid" alt="img description"/>
                  </div>
                  <h3>Step 3</h3>
                  <p>Continue your journey with our <br/>continued support services for the lifetime of your new best friend</p>
                </div>
                <div className="arBtnWrap">
                  <Link to={`/about-us`} className="btn d-block w-100 mx-auto shadow">Follow up
                    Services</Link>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
      <section className="findForeverBlock bg-light" style={{backgroundImage: 'url(/images/ardevpattern03.png)'}}>
        <div className="container">
          <header className="arHeadingHead text-center">
            <h2>Find your forever friend today</h2>
            <div className="text-center arrowBtnWrap">
            <Link to={`/pet-listing`} className="btn btn-primary text-left arbtnHasIcn">Search all pets <i
              className="aricomoon-petClaw aricn"><span className="sr-only">icon</span></i>
            </Link>
            </div>
          </header>
          <div className="row d-block d-sm-flex">
            <div
              className="arffbPostsSlider d-sm-flex flex-sm-wrap justify-content-sm-center arffpsColsHolder">
              {
                adverts?.map((ad) => {
                  return (
                    <React.Fragment key={ad.id}>
                      <div className="col-12 col-sm-6 col-md-4">
                        <article className="um-petInfoIndiWrap rounded shadow bg-white">
                          <div className="um-petInfoIndi">
                            <div className="petPrice rounded shadow">
                              <strong
                                className="d-block font-weight-normal">£{ad.price}</strong>
                            </div>
                            <div className="imgHolderPet rounded">
                              <img
                                src={ad.cover_photo ? `${API_ENDPOINT_URL}/image-retrieve/${ad.cover_photo}` : '/images/image-placeholder.png'}
                                className="img-fluid rounded w-100 h-100 position-absolute"
                                alt="img"/>
                            </div>
                            <div className="petInfoWrap">
                              <h3>{ad.advert_name}</h3>
                              <ul className="petInfoList list-unstyled">
                                <li className="d-flex align-items-center justify-content-between">
                                  <strong>Size</strong>
                                  <ul className="gradList list-unstyled d-flex">
                                    {
                                      getStars(ad.size).map((star, i) => (
                                        <li className={star ? 'active' : ''}
                                            key={i}/>
                                      ))
                                    }
                                  </ul>
                                </li>
                                <li className="d-flex align-items-center justify-content-between">
                                  <strong>Energy</strong>
                                  <ul className="gradList list-unstyled d-flex">
                                    {
                                      getStars(ad.energy).map((star, i) => (
                                        <li className={star ? 'active' : ''}
                                            key={i}/>
                                      ))
                                    }
                                  </ul>
                                </li>
                                <li className="d-flex align-items-center justify-content-between">
                                  <strong>Lifespan</strong>
                                  <ul className="gradList list-unstyled d-flex">
                                    {
                                      getStars(ad.life_span).map((star, i) => (
                                        <li className={star ? 'active' : ''}
                                            key={i}/>
                                      ))
                                    }
                                  </ul>
                                </li>
                                <li className="d-flex align-items-center justify-content-between">
                                  <strong>Grooming</strong>
                                  <ul className="gradList list-unstyled d-flex align-items-center">
                                    {
                                      getStars(ad.grooming).map((star, i) => (
                                        <li className={star ? 'active' : ''}
                                            key={i}/>
                                      ))
                                    }
                                  </ul>
                                </li>
                              </ul>
                              <div className="um-btnformWrap">
                                <Link to={ad?.advert_url}
                                      className="btn btnScnWidth btn-info btnScnStyl text-uppercase">
                                  View
                                </Link>
                                <Link to="#"
                                      className="btn btnScnWidth btn-warning btnScnStyl text-uppercase">Favourite</Link>
                              </div>
                            </div>
                          </div>
                        </article>
                      </div>
                    </React.Fragment>
                  );
                })
              }
              {
                adverts?.length <= 0 &&
                <p className="um-nhcBannerWrap">No Data Found</p>
              }
            </div>
          </div>
        </div>
      </section>
      <section
        className="arwhyBlock text-white bg-primary position-relative overflow-hidden text-center text-lg-left">
        <div className="container">
          <header className="arHeadingHead text-center">
            <h2 className="text-white">Why We Started Petbond</h2>
          </header>
        </div>
        <div className="aaContainerLarge container">
          <div className="row">
            <div className="col-12 offset-lg-5 col-lg-6 col-xlwd-4 position-static">
              <div className="ardescrWrap">
                <p>In this digital age, 1 in 3 of all pups sourced online either die or become very ill
                  during their first year of life!
                  – Most are never vaccinated nor clinically examined by a veterinary surgeon before
                  you invest in a puppy.</p>
                <p>Far from being a happy experience, 1 in 3 people who bought online said their buying
                  experience caused emotional hardship, and financial hardship for 1 in 4 people!
                  – Their lack of care costs you!</p>
                <p>PetBond makes meeting your new “best friend” safe, easy & enjoyable. We are managed
                  by a team of experienced & caring vets, who ensure that all pets from our Approved
                  Breeders & Registered Rescue Centres meet the highest health & welfare standards.
                  We really care about you & your pet, that is why we are PetBond</p>
                <div className="arauthWrap">
                  <div className="imgWrap">
                    <img src="/images/logoTimKirby.png" className="img-fluid"
                         alt="img description"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 position-static">
              <div className="arwhbImgHolder">
                <img src="/images/arimgdev012.png" className="img-fluid" alt="img description"/>
              </div>
            </div>
          </div>
        </div>
        <div className="ahyAwardImg">
          <img src="/images/3stepsaward.svg" className="img-fluid" width="203" height="203"
               alt="the petbond 3 steps process"/>
        </div>
      </section>
      <aside className="bg-warning position-relative artryAsideBlock overflow-hidden text-center text-md-left">
        <div className="aaContainerLarge container centercontent">
          <h3>Set up an alert for your search of your new bestie!  </h3>
          <Link to={`/petbond-match`} className="btn btn-primary arbtnHasIcn">Set up notifications! <i
            className="aricomoon-helpTick aricn aricnvii"><span className="sr-only">icon</span></i></Link>
          <div className="arabImgHolder">
            <img src="/images/hjkyey.tif.png" className="img-fluid" alt="img description"/>
          </div>
        </div>
      </aside>
      <section className="arstoriesBlock bg-light" style={{backgroundImage: 'url(/images/ardevpattern03.png)'}}>
        <div className="container">
          <header className="arHeadingHead text-center">
            <h2>Pet Sounds</h2>
          </header>
          <div className="arstSliderWrap">
            <Slider className="arstorySlider" {...settings}>
              <div>
                <article className="arstStoryColumn text-center text-md-left">
                  <div className="row align-items-center">
                    <div className="col-12 col-md-6">
                      <div
                        className="arstImgHolder overflow-hidden rounded-circle mx-auto mx-md-0">
                        <img src="/images/torsten-dederichs.jpg"
                             className="img-fluid d-block w-100 h-100 rounded-circle"
                             alt="img description"/>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="ardescrWrap">
                        <h3 className="fontBase">Ger &amp; Joyce</h3>
                        <p>We have had the most amazing experience while purchasing a puppy
                          through MyPetBond.com. After much research, we found a breeder in
                          Wexford that comes highly recommended and approved by PetBond. Their
                          professional manner, advice and attention to detail was immense.
                          Our new puppy came IKC registered, heart, hip, and eye certified.
                          All relevant paperwork/best practice neatly laid out in a folder
                          with all their vaccination records. If anyone is thinking of buying
                          a new addition to the family would highly recommend going this
                          route.</p>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              <div>
                <article className="arstStoryColumn text-center text-md-left">
                  <div className="row align-items-center">
                    <div className="col-12 col-md-6">
                      <div
                        className="arstImgHolder overflow-hidden rounded-circle mx-auto mx-md-0">
                        <img src="/images/racheal-john.jpg"
                             className="img-fluid d-block w-100 h-100 rounded-circle"
                             alt="img description"/>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="ardescrWrap">
                        <h3 className="fontBase">Rachel &amp; John</h3>
                        <p>Our names are Rachel and John and we have a small farm in Kilkenny.
                          We have 3 daughters and 3 fabulous furry family members that are the
                          heart of our home.</p>
                        <p>Recently, we were delighted to have a big litter of Golden Retriever
                          puppies from one of our girls and with a lot of thought we decided
                          to advertise our puppies on PetBond. It was the best decision we
                          ever made as all our puppies sold to great homes with the help of
                          the PetBond team. I cant thank them enough for guiding us through
                          the process. We had a great response from the families looking for a
                          new furry baby to join their families. This is the only way we will
                          do things going forward buying, selling or adopting any animal.</p>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              <div>
                <article className="arstStoryColumn text-center text-md-left">
                  <div className="row align-items-center">
                    <div className="col-12 col-md-6">
                      <div
                        className="arstImgHolder overflow-hidden rounded-circle mx-auto mx-md-0">
                        <img src="/images/frankie.jpg"
                             className="img-fluid d-block w-100 h-100 rounded-circle"
                             alt="img description"/>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="ardescrWrap">
                        <h3 className="fontBase">Conal, Alison &amp; Frankie</h3>
                        <p>Thanks so much to Tim and PetBond for helping us find our beautiful
                          Cocker Spaniel Frankie. The reassurance of knowing that our boy was
                          sourced responsibly and from a reliable and registered breeder, made
                          the decision to go with PetBond so easy! Tim covered all the bases
                          with us and was very pleasant to deal with. He made the whole
                          process seamless. We would strongly recommend and encourage anyone
                          to use PetBond as a safe and sure method to get the dog of your
                          dreams.</p>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              <div>
                <article className="arstStoryColumn text-center text-md-left">
                  <div className="row align-items-center">
                    <div className="col-12 col-md-6">
                      <div
                        className="arstImgHolder overflow-hidden rounded-circle mx-auto mx-md-0">
                        <img src="/images/alices-puppy.jpg"
                             className="img-fluid d-block w-100 h-100 rounded-circle"
                             alt="img description"/>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="ardescrWrap">
                        <h3 className="fontBase">Alice</h3>
                        <p>Hello from Nisha. She is transforming my life. I continuously trip up
                          over her toys. I’m up with her at 5 am every morning. I’m walking
                          every day and I’ve located terrific pet friendly coffee shops in the
                          locality. What could be better.....! In short, all is good. You gave
                          me the confidence to buy her. I had dithered for so long afraid I
                          would be entrapped by a puppy farmer and I’m so grateful. Thank you
                          again.</p>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </Slider>
          </div>
          <div className="text-center arrowBtnWrap">
            <a href="https://mypetbond.com/petscorner/category/pet-sounds/"
               className="btn btn-primary text-left arbtnHasIcn">More stories <i
              className="aricomoon-petClaw aricn"><span className="sr-only">icon</span></i></a>
          </div>
        </div>
      </section>
      <section className="arpetSupportBlock">
        <div className="container">
          <div className="row">
            <div className="col-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6">
              <header className="arHeadingHead text-center">
                <h2>We provide you support</h2>
                <p>Welcoming a pet to your life is one of the most wonderful & rewarding experiences you
                  can enjoy. We see this as a bond for life, and our expert support services ensure
                  that you and your pet always receive the best care 24/7. As our way of saying Thank
                  You, our award winning Paw4Paw has improved the lives of thousands of pets less
                  fortunate. Together, we make a real difference.</p>
              </header>
            </div>
          </div>
          <ul className="list-unstyled arpsItemsList d-flex flex-wrap justify-content-center mb-0">
            <li>
              <article className="arpsSupColumn text-center d-flex flex-column w-100">
								<span
                  className="aricnWrap rounded-circle d-flex align-items-center justify-content-center mx-auto">
									<i className="aricomoon-injecClaw"><span className="sr-only">icon</span></i>
								</span>
                <div
                  className="arpscCaption flex-grow-1 d-flex align-items-center justify-content-center">
                  <h3 className="font-weight-bold fontBase">
                    <Link to="/find-a-vet">
                      Find a <br/>Veterinarian
                    </Link>
                  </h3>
                </div>
              </article>
            </li>
            <li>
              <article className="arpsSupColumn text-center d-flex flex-column w-100">
								<span
                  className="aricnWrap rounded-circle d-flex align-items-center justify-content-center mx-auto">
									<i className="aricomoon-awardBone"><span className="sr-only">icon</span></i>
								</span>
                <div
                  className="arpscCaption flex-grow-1 d-flex align-items-center justify-content-center">
                  <h3 className="font-weight-bold fontBase">
                    <a href="https://mypetbond.com/petscorner/general-news/find-a-behaviorist">
                      Find a <br/>Behaviourist
                    </a>
                  </h3>
                </div>
              </article>
            </li>
            <li>
              <article className="arpsSupColumn text-center d-flex flex-column w-100">
								<span
                  className="aricnWrap rounded-circle d-flex align-items-center justify-content-center mx-auto">
									<i className="aricomoon-petDna"><span className="sr-only">icon</span></i>
								</span>
                <div
                  className="arpscCaption flex-grow-1 d-flex align-items-center justify-content-center">
                  <h3 className="font-weight-bold fontBase">
                    <a href="https://mypetbond.com/petscorner/general-news/health-testing">
                      Health <br/>Testing
                    </a>
                  </h3>
                </div>
              </article>
            </li>
            <li>
              <article className="arpsSupColumn text-center d-flex flex-column w-100">
								<span
                  className="aricnWrap rounded-circle d-flex align-items-center justify-content-center mx-auto">
									<i className="aricomoon-clawHeart"><span className="sr-only">icon</span></i>
								</span>
                <div
                  className="arpscCaption flex-grow-1 d-flex align-items-center justify-content-center">
                  <h3 className="font-weight-bold fontBase">
                    <a href="https://mypetbond.com/petscorner/general-news/paw4paw">
                      PAW4PAW
                    </a>
                  </h3>
                </div>
              </article>
            </li>
          </ul>
          <div className="text-center arrowBtnWrap d-md-none">
            <a href="#" className="btn btn-primary text-left arbtnHasIcn">Read More <i
              className="aricomoon-petClaw aricn"><span className="sr-only">icon</span></i></a>
          </div>
        </div>
      </section>
      <aside className="arsignupAsideBlock position-relative text-center text-lg-left">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <h3 className="fontBase text-lg-left">Are <strong
                className="font-weight-normal fontFredoka">YOU</strong> a responsible breeder?</h3>
              <div className="arextraWrap mx-auto mx-lg-0">
                <p>Caring and responsible breeders deserve a voice, and we are here to fully support
                  you. PetBond breeders prioritise their pet's health & welfare above all else,
                  because they truly care. If you would like to join our growing trusted breeder
                  network.</p>
              </div>
            </div>
            <div className="col-12 col-lg-6 position-static">
              <div className="arsabImgHolder">
                <img src={flyoutPuppyIsolated} className="img-fluid" alt="img description"/>
              </div>
            </div>
          </div>
          <div className="text-center arrowBtnWrap">
            <Link to={`/auth/breeder-signup`} className="btn arbtnWhite shadow text-left arbtnHasIcn">Advertise with us  <i className="aricomoon-petClaw aricn"><span
                className="sr-only">icon</span></i></Link>
          </div>
        </div>
        <span className="arabPetIcn arabPetIcnviii position-absolute">
					<i className="aricomoon-laoud"><span className="sr-only">icon</span></i>
				</span>
      </aside>
      <section className="arlatestStoryBlock" style={{backgroundImage: 'url(/images/ardevpattern03.png)'}}>
        <div className="container">
          <header className="arHeadingHead text-center">
            <h2>Latest news</h2>
          </header>
          <div className="row d-block d-sm-flex">
            <div
              className="arffbPostsSlider d-sm-flex justify-content-sm-center flex-sm-wrap arffpsColsHolder">
              {
                stories?.map((story) => {
                  return (
                    <React.Fragment key={story.id}>
                      <div className="col-12 col-sm-6 col-md-4">
                        <article
                          className="um-petCorWrap um-petCorWrapvii rounded shadow bg-white text-center position-relative">
                          <div className="imgHolderPet rounded position-relative">
                            <img src={story.image}
                                 className="img-fluid rounded w-100 h-100 position-absolute"
                                 alt="img description"/>
                          </div>
                          <div className="petInfoWrap">
                            <h3>{story.title}</h3>
                            <p>{story.shortContent}</p>
                            <a href={story.link}
                               className="arbtnLinkMore d-inline-block align-top">Read
                              More</a>
                          </div>
                        </article>
                      </div>
                    </React.Fragment>
                  );
                })
              }
              {
                stories.length <= 0 &&
                <p className="um-nhcBannerWrap">No Data Found</p>
              }
            </div>
          </div>
          <div className="text-center arrowBtnWrap">
            <a href="https://mypetbond.com/petscorner"
               className="btn btn-primary shadow text-left arbtnHasIcn">Latest news<i
              className="aricomoon-petClaw aricn"><span className="sr-only">icon</span></i></a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
